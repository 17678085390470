import React, { useState, useEffect } from 'react';
import { Table, Input } from "antd";
import { useGetAppointmentMutation } from '../../redux/slices/patientSlice';
import moment from 'moment';
import debounce from '../../HelperFunctions';

const Appointment = () => {
    const [getAppointment, { isLoading }] = useGetAppointmentMutation();
    const [appointmentPage, setApponmtmentPage] = useState(1);
    const [appointmentPerPage, setApponmtmentPerPage] = useState(10);
    const [appointmentTotal, setAppointmentTotal] = useState(0);
    const [filteredAppointmentTotal, setFilteresAppointmentTotal] = useState(0);
    const [filteredAppointmentData, setFilteredAppointmentData] = useState([]);
    const [search, setSearch] = useState('');
    // const hasRunEffect = useRef(false);

    const getAppointmentList = async (value) => {
        try {
            let res = await getAppointment(value);
            setFilteredAppointmentData(res?.data?.data)
            setAppointmentTotal(res?.data?.total)
            setFilteresAppointmentTotal(res?.data?.total)
        }
        catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        let query = `?per_page=${appointmentPerPage}&page=${appointmentPage}`;
        if (search !== '') {
            query += `&search=${search}`
        }
        getAppointmentList(query)
    }, [appointmentPerPage, appointmentPage]);

    const onPageSizeChange = (current, size) => {
        setApponmtmentPerPage(size);
        setApponmtmentPage(1);
    };

    const generatePageSizeOptions = (total) => {
        const options = [];
        const maxPageSize = Math.min(total, 100);

        for (let i = 5; i <= maxPageSize; i += 5) {
            options.push(i);
        }
        if (total > 5 && !options.includes(10)) {
            options.push(10);
        }

        if (total > 10 && !options.includes(15)) {
            options.push(15);
        }
        return options.sort((a, b) => a - b);
    };

    const pageSizeOptions = generatePageSizeOptions(appointmentTotal);

    const tableColumns = [
        {
            title: "S.No",
            key: "serial_number",
            render: (text, record, index) => {
                const currentPage = appointmentPage;
                const pageSize = appointmentPerPage;
                return <span>{(currentPage - 1) * pageSize + index + 1}</span>;
            },
        },
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            key: "patient_name",
            // sorter: (a, b) => a.patient_name.length - b.patient_name.length,
            render: (text, record) => (
                <>
                    <span>{record.patient_name ? record.patient_name : "-"}</span>
                </>
            )
        },
        {
            title: "Booking",
            dataIndex: "booked",
            key: "booked",
            // sorter: (a, b) => a.booked.length - b.booked.length,
            render: (text, record) => (
                <>
                    <span>{record?.online_versus_inhouse}</span>
                </>
            )
        },
        {
            title: "Event Type",
            dataIndex: "event_type",
            key: "event_type",
            // sorter: (a, b) => a.event_type.length - b.event_type.length,
            render: (text, record) => (
                <>
                    <span>{record?.event_type}</span>
                </>
            )
        },
        {
            title: "Service Title",
            dataIndex: "service_title",
            key: "service_title",
            // sorter: (a, b) => a.service_title.length - b.service_title.length,
            render: (text, record) => (
                <>
                    <span>{record?.service_title}</span>
                </>
            )
        },
        {
            title: "Category",
            dataIndex: "service_category",
            key: "service_category",
            // sorter: (a, b) => a.service_category.length - b.service_category.length,
            render: (text, record) => (
                <>
                    <span>{record?.service_category}</span>
                </>
            )
        },
        {
            title: "Booking Time",
            dataIndex: "booktime",
            key: "booktime",
            // sorter: (a, b) => a.booktime.length - b.booktime.length,
            render: (text, record) => (
                <>
                    <span>{moment(record.appointment_created_date).format('MM/DD/YY')}</span>
                </>
            )
        },
        {
            title: "Next Appointment",
            dataIndex: "next_appointment",
            key: "next_appointment",
            // sorter: (a, b) => a.booktime.length - b.booktime.length,
            render: (text, record) => (
                <>
                    <span>{record?.upcoming_appointment_date? moment(record?.upcoming_appointment_date).format('MM/DD/YY'):"N/A"}</span>
                </>
            )
        },
        {
            title: "Last Updated",
            dataIndex: "lastseen",
            key: "lastseen",
            // sorter: (a, b) => a.lastseen.length - b.lastseen.length,
            render: (text, record) => (
                <>
                    <span>{moment(record.updated_at).format('MM/DD/YY')}</span>
                </>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
                <>
                    <span>{record?.booking_status}</span>
                </>
            )
        },
    ];

    const handleAppointmentSearch = async (event) => {
        setSearch(event?.target?.value);
        let query = `?per_page=${appointmentPerPage}&page=${appointmentPage}`;
        if (event?.target?.value !== '') {
            query += `&search=${event?.target?.value}`
        }
        let res = await debounce(getAppointment, query)
        setFilteredAppointmentData(res?.data?.data)
        setAppointmentTotal(res?.data?.total)
        setFilteresAppointmentTotal(res?.data?.total)
    };

    return (
        <div className="wrapper">
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="titleBar titleRow">
                            <h4>Appointments List</h4>
                            <Input
                                placeholder="Search here.."
                                onChange={handleAppointmentSearch}
                                className="homesearchInput titleRowInput"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tableWrapper">
                            <Table
                                className="registered-user-table"
                                columns={tableColumns}
                                dataSource={filteredAppointmentData}
                                pagination={{
                                    current: appointmentPage,
                                    pageSize: appointmentPerPage,
                                    total: filteredAppointmentTotal,
                                    onChange: (page) => setApponmtmentPage(page),
                                    onShowSizeChange: onPageSizeChange,
                                    showSizeChanger: true,
                                    showQuickJumper: true,
                                    pageSizeOptions: pageSizeOptions,
                                }}
                                loading={isLoading}
                                showSorterTooltip={false}
                                scroll={{ x: 'auto' }}
                                bordered
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Appointment;
